import React from "react"
import "../global.css"
import Highlights from "../subpages/Highlights"
import Insurance from "../subpages/Insurance"
import End from "../subpages/End";
import AppartementMenu from "../components/AppartementMenu";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PreisBox from "../components/PreisBox"
import SeoHelmet from "../components/seo"
import Fade from 'react-reveal/Fade';

function Ferienwohnung1() {
  const { allFile } = useStaticQuery(graphql`
    query App1Photos {
      allFile(filter: {relativeDirectory: {eq: "appartements/1"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const galleryImages = allFile.edges.map(img => ({
    thumbnail: img.node.childImageSharp.fluid.src,
    renderItem: () => (
      <Img
        fluid={{
          ...img.node.childImageSharp.fluid,
        }}
        imgStyle={{
          objectFit: "contain",
        }}
        alt={"Ferienwohnung 1"}
      />
    ),
  }))

  return (
    <div>
      <SeoHelmet 
        title="Ferienwohnung mit 47 m² für 4 Personen - Appartement in Werfenweng - Haus Gruber Ferienwohnungen"
        description="Das Haus Gruber Ferienwohnungen bietet Ihnen geräumige und gemütlich eingerichtete Appartements. Wir freuen uns auf Ihren Besuch!"
      />
      <Header height={60}/>
      <AppartementMenu active={1} />
      <div className="container mb-3">
        <div className="flexbox">
          <Fade>
          <h1 className="text-center my-5">Ferienwohnung 1<br/><small>47 m² für 4 Personen</small></h1>
          </Fade>
          <div className="row no-gutters position-relative">
          <Fade>
            <div className="col-md-12 mb-md-0">
              <h5>Beschreibung</h5>
              <p>Die Wohnung verfügt über <b>zwei Schlafzimmer</b> (1x Doppelbett, einmal getrennte Betten), eine <b>großzügige Wohnküche</b>, Bad und WC getrennt, sowie einen Balkon.
              In der Wohnküche auf der Couch ist noch Platz für bis eine weitere Person.</p>
              <h5>Ausstattung</h5>
              <p>Dusche und WC sind getrennt, TV, kostenlose WLAN-Benutzung. Die Küche ist mit vier Ceran-Herdplatten (kein Backrohr), Mikrowelle, Wasserkocher, Toaster, Filterkaffeemaschine sowie “Cremesso” Kapsel-Kaffeemaschine und sämtlichem Koch- und Tafelgeschirr ausgestattet. Bettwäsche, Hand- und Duschtücher sowie auch ein Haartrockner, Seife und Shampoo sind ebenfalls vorhanden.</p>
              <h6>Schlafzimmer</h6>
              <p>2 separate, helle freundliche Schlafzimmer, welche mit Allergiker-Bettwäsche ausgestattet sind.</p>
              </div>
          </Fade>
          </div>
          <div className="row no-gutters position-relative mt-2">
            <div className="col-md-6 mb-md-0 pr-4">
              <h5>Preise</h5>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="p-2 flex-fill">
                  <PreisBox 
                    title={"Sommersaison"}
                    price={108}
                    cleaning={60}
                    color={'rgb(255, 205, 108)'}
                    options={[
                      'Frühling: € 108,-<br><small>15.03.25 - 21.06.25</small>',
                      'Sommer: € 120,-<br><small>21.06.25 - 30.08.25</small>',
                      'Herbst: € 108,-<br><small>30.08.25 - 11.10.25</small>',
                    ]}
                  />
                </div>
                <div className="p-2 flex-fill">
                  <PreisBox
                  title={"Wintersaison"}
                  price={126}
                  cleaning={60}
                  color={'rgb(107, 166, 255)'}
                  options={[
                    'Weihnachten: € 138,-<br><small>14.12.24 - 11.01.25</small>',
                    'Zwischensaison: € 126,-<br><small>11.01.25 - 01.02.25</small>',
                    'Energiewochen: € 138,-<br><small>01.02.25 - 08.03.25</small>',
                    'Nachsaison: € 126,-<br><small>08.03.25 - 15.03.25</small>',
                  ]}
                />
                </div>
              </div>
              <p><small>Preise pro Nacht und Appartement in Euro. Die Preise beinhalten alle Nebengebüren wie Strom, Wasser, Wäsche exklusive der Ortstaxe* von EUR 2,- pro Person Tag für Gäste ab dem 15. Lebensjahr</small></p>
              <p><small>* Ab 11.01.2025 wir die Ortstaxe auf EUR 2,65 pro Person erhöht.</small></p>
            </div>
            <div className="col-md-6 position-static p-2 pl-md-0">
              <h5>Fotos</h5>
              <ImageGallery
                items={galleryImages}
                thumbnailPosition="bottom"
                showNav={true}
                showFullscreenButton={false}
                infinite={false}
                showPlayButton={false}
                lazyLoad={true}
                showIndex={true}
              />
            </div>
          </div>
        </div>
        </div>
      <Highlights />
      <Insurance />
      <End/>
      <Footer/>
    </div>
  )
}

export default Ferienwohnung1
